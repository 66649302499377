<template>
    <div class="h-100 mt-5">
        <div v-if="catalogClasses.length > 0">
            <div class="card card-custom flex-row justify-content-between align-items-center px-5">
                <h4 class="font-weight-bolder text-primary my-5">
                    Catalogue Si2P
                    <span class="mx-2 text-muted"> ( {{ catalogClasses.length }} ) </span>
                </h4>
            </div>
            <section class="row d-flex mt-2 mb-0 displayWrap">
                <article
                    v-for="item in catalogClasses"
                    :key="item.id"
                    class="card card-stretch gutter-b justify-content-between mr-6 border-primary py-5 col-md-3 border-0"
                    @mouseenter="hoverId = item.id"
                    @mouseleave="hoverId = null"
                >
                    <div class="d-flex flex-column justify-content-between" style="height:85%">
                        <div
                            v-if="!item.on"
                            v-b-tooltip.hover.bottom="'Classe temporairement indisponible.  Son contenu est en cours de révision.'"
                            style="position:absolute;top:10%;background-color: #ffffff70;backdrop-filter: blur(9px); left: 0;"
                            class="border-top border-bottom border-danger py-1 m-0 text-danger w-100"
                        >
                            <h4 class="my-5 text-center font-weight-bolder">
                                Indisponible
                            </h4>
                        </div>
                        <span class="classe-img-container d-flex justify-content-center" style="height:12rem; overflow:hidden">
                            <img class="classe-img" :src="item.miniature" alt="image de la classe" />
                        </span>
                        <div class="card-header border-0 justify-content-center py-0 mt-5">
                            <h3 class="card-title font-weight-bolder text-dark text-center mb-0">{{ item.title.toUpperCase() }}</h3>
                        </div>
                        <div class="classe-icon-container mt-6 ">
                            <div class="classe-icon" v-if="item.duration">
                                <img src="/media/svg/icons/time-icon.svg" alt="Icon horloge" width="20px" />
                                <p>{{ item.duration }} minutes</p>
                            </div>
                            <div class="classe-icon">
                                <img src="/media/svg/icons/cup-icon.svg" alt="Icon trophée" width="20px" />
                                <p>{{ item.goal }}%</p>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center align-items-center mt-6 mb-3">
                            <b-icon
                                v-if="isIconHovered"
                                icon="plus-circle"
                                variant="primary"
                                class="develop-card"
                                @click="selectItem(item.id)"
                                @mouseover="iconHover(item.id)"
                                v-bind:class="{ hidden: item.id === activeItem }"
                            >
                            </b-icon>
                            <b-icon
                                v-else
                                :icon="activeIcon === item.id ? 'plus-circle-fill' : 'plus-circle'"
                                variant="primary"
                                class="develop-card"
                                @click="selectItem(item.id)"
                                @mouseleave="iconHover(item.id)"
                                v-bind:class="{ hidden: item.id === activeItem }"
                            >
                            </b-icon>
                            <b-icon
                                v-if="isIconHovered"
                                icon="dash-circle"
                                variant="primary"
                                class="hidden develop-card"
                                @click="selectItem(item.id)"
                                @mouseover="iconHover(item.id)"
                                v-bind:class="{ active: item.id === activeItem }"
                            >
                            </b-icon>
                            <b-icon
                                v-else
                                :icon="activeIcon === item.id ? 'dash-circle-fill' : 'dash-circle'"
                                variant="primary"
                                class="hidden develop-card"
                                @click="selectItem(item.id)"
                                @mouseleave="iconHover(item.id)"
                                v-bind:class="{ active: item.id === activeItem }"
                            >
                            </b-icon>
                        </div>
                    </div>
                    <div
                        class="hidden card-footer pt-5 pb-1 justify-content-center flex-md-column"
                        v-bind:class="{ active: item.id === activeItem }"
                    >
                        <nl2br v-if="item.description" tag="p" :text="item.description" class-name="nl2br border-secondary" />

                        <div>
                            <b-button
                                v-if="$store.getters.currentUser.role === 'gestionnaire'"
                                @click="$bvModal.show('modal-confirmation-' + hoverId)"
                                class="mt-4 w-100"
                                pill
                                variant="outline-primary"
                            >
                                Demande de rappel
                            </b-button>
                            <b-modal v-bind:id="'modal-confirmation-' + item.id" title="Demande de rappel">
                                <p class="my-4">
                                    Après confirmation, une demande de rappel sera envoyée à votre interlocuteur. Ce dernier vous
                                    recontactera sous 48h.
                                </p>
                                <template #modal-footer>
                                    <b-button
                                        variant="outline-danger"
                                        pill
                                        class="float-right"
                                        @click="$bvModal.hide('modal-confirmation-' + item.id)"
                                    >
                                        Annuler
                                    </b-button>
                                    <b-button
                                        variant="outline-primary"
                                        pill
                                        class="float-right"
                                        @click="classeRappel(item.id), $bvModal.hide('modal-confirmation-' + item.id)"
                                    >
                                        Confirmer
                                    </b-button>
                                </template>
                            </b-modal>
                        </div>
                    </div>
                </article>
            </section>
        </div>
    </div>
</template>
<style scoped>
.hidden {
    display: none;
}

.active {
    display: flex;
}

.classe-icon-container {
    display: flex;
    justify-content: space-evenly;
}

.classe-icon {
    display: flex;
    align-items: center;
}

.classe-icon p {
    margin-bottom: 0;
    margin-left: 0.8rem;
}

article {
    margin-bottom: 2vh;
}

article.card {
    box-shadow: 6px 6px 7px 4px rgba(184, 184, 184, 0.68);
    -webkit-box-shadow: 6px 6px 7px 4px rgba(184, 184, 184, 0.68);
    -moz-box-shadow: 6px 6px 7px 4px rgba(184, 184, 184, 0.68);
}

.classe-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.classe-img {
    max-width: 90%;
    max-height: 100%;
    width: auto;
    height: auto;
}

div.card-header {
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.progress-bar-container {
    height: 2rem;
}

div.progress span {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scrollPanel::-webkit-scrollbar {
    height: 2vh;
    background-color: #f1f1f1;
    z-index: 9999;
}

.scrollPanel::-webkit-scrollbar-thumb {
    background-color: #085a82;
    border-radius: 1em;
}

.displayScroll {
    height: 60vh;
    overflow-x: auto;
    flex-wrap: nowrap;
}

.displayScroll > article {
    min-width: 25em;
}

.displayWrap {
    padding: 2vh 0;
    height: auto;
    overflow-x: hidden;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start !important;
}

.displayWrap > article {
    margin-bottom: 5vh;
    min-width: 30em;
}

.bi {
    width: 2rem;
    height: 2rem;
}

.develop-card {
    cursor: pointer;
}

.nl2br {
    max-height: 7vh;
}

.customScroll {
    overflow-y: auto;
}

.customScroll::-webkit-scrollbar-track {
    background-color: #f3f6f9;
}

.customScroll::-webkit-scrollbar {
    width: 0.25em;
    background-color: #f3f6f9;
}

.customScroll::-webkit-scrollbar-thumb {
    background-color: #085a82;
}
</style>

<script>
import { LIST_CATALOG_CLASSES } from '@/core/services/store/api/classe.service';
import { mapGetters } from 'vuex';
import Nl2br from 'vue-nl2br';
import axios from 'axios';

export default {
    mounted() {
        this.$store.dispatch(LIST_CATALOG_CLASSES, { user_id: this.$store.getters.currentUser.id });
    },
    computed: {
        ...mapGetters({ catalogClasses: 'getCatalogClasses' }),
        mobile() {
            return window.screen.availWidth > 992 ? false : true;
        }
    },
    components: {
        Nl2br
    },
    data() {
        return {
            isIconHovered: false,
            activeIcon: null,
            activeItem: null,
            isHidden: true,
            hoverId: null
        };
    },
    methods: {
        selectItem(itemdId) {
            if (this.activeItem == null) {
                this.activeItem = itemdId;
            } else if (this.activeItem == itemdId) {
                this.activeItem = null;
            } else {
                this.activeItem = null;
                this.activeItem = itemdId;
            }
        },
        iconHover(itemdId) {
            this.activeIcon = itemdId;
            this.isIconHovered = !this.isIconHovered;
        },
        classeRappel(itemId) {
            axios.post(process.env.VUE_APP_API_URL + '/academy/classes/rappel/' + itemId);
        }
    }
};
</script>
